import { styled } from '@mui/material'

export const AnimationWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 200,
  height: 200,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: theme.palette.background.paper,

  [theme.breakpoints.down('md')]: {
    transform: 'scale(0.6)',
  },
}))
