export const grey = {
  50: '#F2F2F2',
  100: '#E6E6E6',
  200: '#CCCCCC',
  300: '#B3B3B3',
  400: '#999999',
  500: '#878787',
  600: '#666666',
  700: '#4D4D4D',
  800: '#333333',
  A800: '#22262B',
  900: '#1A1A1A',
  950: '#0D0D0D',
}
