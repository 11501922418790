import {
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { configs, grey } from '@shared/constants'
import { useMobileWebviewStore } from '@shared/hooks'
import { getCookie, setCookie } from '@shared/utils'
import {
  StyledCookieWrapper,
  StyledInsideWrapper,
  StyledSpacingElement,
} from './styled'

const cookiesName = 'COOKIES_POLICY'

export const CookieConfirmation: FC<{ spacing?: boolean }> = ({ spacing }) => {
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation('components')
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const { pathToLanding } = configs()
  const isMobile = useMobileWebviewStore((state) => state.isMobile)
  const cookies = getCookie(cookiesName)

  const cookiesShowingCondition = () => {
    setShow(cookies !== '1' && !isMobile)
  }

  const acceptCookiePolicy = () => {
    setCookie(cookiesName, '1', 365)

    setShow(false)
  }

  useEffect(() => {
    cookiesShowingCondition()
  }, [isMobile, cookies])

  if (!show) {
    return <></>
  }

  return (
    <StyledCookieWrapper spacing={spacing}>
      <StyledInsideWrapper>
        {spacing && <StyledSpacingElement />}

        <Typography
          sx={{ maxWidth: '730px' }}
          fontSize={'12px'}
          textAlign={mdAndUp ? 'start' : 'center'}
        >
          <Trans
            i18nKey={'cookies.confirmation'}
            ns={'components'}
            components={{
              a: (
                <Link
                  component={'a'}
                  target={'_blank'}
                  href={`${pathToLanding}/privacy-policy`}
                  sx={{ color: grey[400], display: 'inline' }}
                />
              ),
            }}
          />
        </Typography>

        <Button onClick={acceptCookiePolicy} sx={{ minWidth: '130px' }}>
          {t('cookies.accept')}
        </Button>
      </StyledInsideWrapper>
    </StyledCookieWrapper>
  )
}
