import { Box, IconButton } from '@mui/material'
import { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SwipeableHandlers } from 'react-swipeable'
import Webcam from 'react-webcam'

import TakePhotoIcon from './assets/take-photo-icon'

import {
  PhoneHighLayout,
  PhoneHighLayoutFooter,
  PhoneHighLayoutPlaceForPhoto,
  PhoneHighLayoutTextSubtitle,
  PhoneHighLayoutTextTitle,
  PhoneHighLayoutTitle,
  PhoneLayoutWrapper,
} from './styled'

interface IProps {
  handlers: SwipeableHandlers
  videoConstraints: {
    width: { min: number }
    height: { min: number }
    aspectRatio: number
  }
  takePhoto: () => void
  title: string
  subtitle: string | JSX.Element
}

function MobileWebcamWithRef(
  { handlers, title, subtitle, takePhoto, videoConstraints }: IProps,
  webcamRef: ForwardedRef<Webcam | null>
) {
  const { t } = useTranslation('pages')

  return (
    <PhoneLayoutWrapper>
      <PhoneHighLayout {...handlers}>
        <PhoneHighLayoutTitle>
          {t('landing.identityVerification.To exit full screen mode')}
        </PhoneHighLayoutTitle>

        {/*Center content -> webcam block with border*/}
        <Box position={'relative'} zIndex={-1}>
          <PhoneHighLayoutPlaceForPhoto />

          <Webcam
            mirrored
            style={{
              position: 'absolute',
              zIndex: -1,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            imageSmoothing={false}
            screenshotQuality={1}
            videoConstraints={videoConstraints}
            width={videoConstraints.width.min}
            minScreenshotWidth={videoConstraints.width.min}
            minScreenshotHeight={videoConstraints.height.min}
          />
        </Box>

        {/*Footer content to take photo*/}
        <PhoneHighLayoutFooter>
          <Box>
            <PhoneHighLayoutTextTitle>{title}</PhoneHighLayoutTextTitle>

            <PhoneHighLayoutTextSubtitle
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </Box>

          <IconButton onClick={takePhoto} sx={{ mg: 5.75 }}>
            <TakePhotoIcon sx={{ fontSize: 70 }} />
          </IconButton>
        </PhoneHighLayoutFooter>
      </PhoneHighLayout>
    </PhoneLayoutWrapper>
  )
}

export const FrMobileView = forwardRef(MobileWebcamWithRef)
