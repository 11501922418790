import { Button, styled } from '@mui/material'

export const StyledLinkButton = styled(Button)(({ theme }) => ({
  width: 78,
  marginRight: theme.spacing(-2),
  perspective: '10000px',
  color: theme.palette.text.primary,
  ':hover': {
    backgroundColor: 'initial',
    color: theme.palette.primary.main,
  },
  '& .MuiButton-endIcon': {
    marginLeft: 0,
    '& svg': {
      fontSize: 24,
    },
  },
}))
