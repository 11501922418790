import { Container } from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import { useEffect } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { AppBar } from '@widgets/app-bar'

import { AppLoader, useAppLoaderStore } from '@entities/app-loader'

import { useAuth } from '@shared/auth'

import { useWebviewSetIsMobile } from '@shared/hooks'

import { Wrapper } from './styled'

export function MainLayout() {
  const setShowLoader = useAppLoaderStore((state) => state.setShow)

  const { store, fetchUser, fetchUserDetailInfo } = useAuth()

  const { keycloak } = useKeycloak()

  const fetchUserDetailInfoAndHideLoader = async () => {
    if (!store.user) {
      setShowLoader(true)

      return
    }

    if (store.user.statusOndata !== 'APPROVED') {
      setShowLoader(false)
    } else if (store.user.statusOndata === 'APPROVED') {
      await fetchUserDetailInfo()

      setTimeout(() => {
        setShowLoader(false)
      }, 50)
    }
  }

  useEffect(() => {
    fetchUserDetailInfoAndHideLoader()
  }, [store.user])

  useEffect(() => {
    setShowLoader(true)

    if (keycloak.authenticated) {
      fetchUser()
    }
  }, [])

  useWebviewSetIsMobile()

  return (
    <Wrapper>
      <ScrollRestoration />
      <AppLoader />
      <AppBar />

      <Container
        maxWidth="xl"
        sx={{
          mb: {
            xs: 5,
            md: 4,
          },
          flex: {
            xs: 1,
            md: 0,
          },
          display: 'flex',
        }}
      >
        <Outlet />
      </Container>

      {/*<Footer />*/}
    </Wrapper>
  )
}
