import { Stack, SxProps } from '@mui/material'
import { useState } from 'react'

import { ProfileButton } from '@entities/app-bar'

import { ProfileMenu } from './profile-menu'

interface IProps {
  sx?: SxProps
}

export function Profile({ sx }: IProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack sx={{ justifyContent: 'center', pt: 0.25, ...sx }}>
      <ProfileButton handleClick={handleClick} open={open} />
      <ProfileMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
    </Stack>
  )
}
