import { Box, keyframes } from '@mui/material'

import RocketImg from '../assets/rocket.svg'
import { RocketBox } from './styled'

export const RocketAnimation = keyframes`
    50% {
      margin-top: 30px;
    }
  `

export function Rocket() {
  return (
    <RocketBox>
      <Box
        component="img"
        src={RocketImg}
        alt="Rocket"
        sx={{
          marginTop: '-10px',
          animation: `${RocketAnimation} 3s infinite ease`,
        }}
      />
    </RocketBox>
  )
}
