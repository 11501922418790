import { styled } from '@mui/material'

export const PhoneHighLayoutTitle = styled('h3')(({ theme }) => ({
  margin: 0,
  padding: '12px 20px',
  background: 'rgba(48, 48, 48, 0.5)',
  borderRadius: '100px',
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  textAlign: 'center',
  color: '#FFFFFF',
  zIndex: theme.zIndex.appBar + 105,
  marginLeft: '28px',
  marginRight: '28px',
}))
