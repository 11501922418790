import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface IIsMobileStore {
  isMobile: boolean
  setIsMobile: (isMobile: boolean) => void
}

export const useIsMobileStore = create<IIsMobileStore>()(
  devtools((set) => ({
    isMobile: false,
    setIsMobile: (isMobile) => set({ isMobile }),
  }))
)
