import { CircularProgress } from '@mui/material'

import { useAppLoaderStore } from '../model'
import { Rocket } from './components'
import { Wrapper, AnimationWrapper, StarsBox, SpaceBox } from './styled'

interface IProps {
  showManual?: boolean
}

export function AppLoader({ showManual }: IProps) {
  const { show } = useAppLoaderStore()

  if (!showManual && !show) {
    return null
  }

  return (
    <Wrapper>
      <AnimationWrapper>
        <CircularProgress
          size={192}
          sx={{ position: 'absolute', top: 4, left: 4, zIndex: 1 }}
          disableShrink
        />

        <SpaceBox>
          <StarsBox />
          <Rocket />
        </SpaceBox>
      </AnimationWrapper>
    </Wrapper>
  )
}
