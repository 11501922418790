import { TypographyOptions } from '@mui/material/styles/createTypography'

interface ExtendedTypographyOptions extends TypographyOptions {
  subtitle3: React.CSSProperties
  subtitle4: React.CSSProperties
  subtitle5: React.CSSProperties

  body3: React.CSSProperties
  body4: React.CSSProperties
  body5: React.CSSProperties
  body6: React.CSSProperties
  body7: React.CSSProperties
  body8: React.CSSProperties
  body9: React.CSSProperties
  body10: React.CSSProperties
  body11: React.CSSProperties
  body12: React.CSSProperties
  body13: React.CSSProperties
  body14: React.CSSProperties
  body15: React.CSSProperties
  body16: React.CSSProperties
  body17: React.CSSProperties
  body18: React.CSSProperties
  body19: React.CSSProperties
  body20: React.CSSProperties

  link1: React.CSSProperties
  link2: React.CSSProperties

  button1: React.CSSProperties
  button2: React.CSSProperties
  button3: React.CSSProperties

  input1: React.CSSProperties
  input2: React.CSSProperties

  numeric1: React.CSSProperties

  avatar: React.CSSProperties
}

export const typographyOptions: ExtendedTypographyOptions = {
  fontFamily: [
    'Simplon Norm Trial',
    'sans-serif',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(','),
  h1: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1.2,
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: 1.2,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: 1.2,
  },
  subtitle3: {
    fontWeight: 700,
    fontSize: 25,
    lineHeight: 1.2,
  },
  subtitle4: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.2,
  },
  subtitle5: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.4,
  },

  body1: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.5,
  },
  body2: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  body3: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
  },
  body4: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
  },
  body5: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.1,
  },
  body6: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body7: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  body8: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body9: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.5,
  },
  body10: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body11: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
  },
  body12: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
  },
  body13: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.5,
  },
  body14: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  body15: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body16: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.1,
  },
  body17: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
  },
  body18: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  body19: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body20: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
  },

  link1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  link2: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  button1: {
    fontWeight: 500,
    fontSize: 22,
    lineHeight: 1.3,
  },
  button2: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.3,
  },
  button3: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.3,
  },

  input1: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.2,
  },
  input2: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 'normal',
  },

  numeric1: {
    fontFamily: 'Roboto',
    fontWeight: 200,
    fontSize: 40,
    lineHeight: 1.2,
  },

  avatar: {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: 12,
  },
}
