import { PropsWithChildren } from 'react'

import { ConfirmationProvider } from './confirmation-provider'
import { KeycloakProvider } from './keycloak-provider'
import { ThemeProvider } from './theme'
import { TimeLocale } from './time-locale-provider'

export function ComposeProvider({ children }: PropsWithChildren<object>) {
  return (
    <ThemeProvider>
      <KeycloakProvider>
        <ConfirmationProvider>
          <TimeLocale>{children}</TimeLocale>
        </ConfirmationProvider>
      </KeycloakProvider>
    </ThemeProvider>
  )
}
