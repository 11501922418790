import { useKeycloak } from '@react-keycloak/web'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { IdentityVerificationRoute } from '@pages/identity-verification'

import { AppLoader } from '@entities/app-loader'
import { MainLayout, ErrorLayout } from './layouts'

const browserRouter = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <ErrorLayout />,
    children: [IdentityVerificationRoute],
  },
])

export function RouterViewer() {
  const { keycloak, initialized } = useKeycloak()

  if (!initialized || !keycloak.authenticated) {
    return <AppLoader showManual />
  }

  return <RouterProvider router={browserRouter} />
}
