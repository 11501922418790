import { SxProps } from '@mui/material'
import { ForwardedRef, forwardRef } from 'react'
import Webcam from 'react-webcam'

import SelfieVectorSVG from './assets/selfie-vector'

import { UnderWrapper, Wrapper } from './styled'

interface IProps {
  sx?: SxProps
  videoConstraints: {
    width: { min: number }
    height: { min: number }
    aspectRatio: number
  }
  startProcess: boolean
}

function DesktopWebcamWithRef(
  { sx, videoConstraints, startProcess }: IProps,
  webcamRef: ForwardedRef<Webcam | null>
) {
  return (
    <Wrapper sx={sx}>
      {!startProcess ? (
        <SelfieVectorSVG />
      ) : (
        <UnderWrapper>
          <Webcam
            videoConstraints={videoConstraints}
            mirrored={true}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            imageSmoothing={false}
            screenshotQuality={1}
            style={{ objectFit: 'cover' }}
            height={videoConstraints.height.min}
            width={videoConstraints.width.min}
            minScreenshotWidth={videoConstraints.width.min}
            minScreenshotHeight={videoConstraints.height.min}
          />
        </UnderWrapper>
      )}
    </Wrapper>
  )
}

export const FrDesktopView = forwardRef(DesktopWebcamWithRef)
