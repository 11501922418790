import { styled } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  width: '100%',
  background: '#f1f1f1',
  borderRadius: 16,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  paddingTop: 0,
  objectFit: 'cover',
  aspectRatio: '1.47',
  '& video': {
    width: '100%',
  },
}))
