import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '14px 52px',
  borderRadius: theme.spacing(1),
  fontSize: 20,
  fontWeight: 500,
  lineHeight: '120%',
  textTransform: 'uppercase',
}))
