import { SvgIcon, SxProps } from '@mui/material'

interface IProps {
  sx?: SxProps
}

export function TakePhotoIcon({ sx }: IProps) {
  return (
    <SvgIcon
      sx={sx}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35" cy="35" r="35" fill="white" />

      <circle
        cx="35"
        cy="35"
        r="28"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
    </SvgIcon>
  )
}
