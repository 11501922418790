import { TBase64String } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isBase64String(value: any): value is TBase64String {
  try {
    Buffer.from(value, 'base64').toString('utf-8')

    return true
  } catch {
    return false
  }
}
