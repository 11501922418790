import { AxiosError } from 'axios'
import { TypeOptions } from 'react-toastify'

import { errorHandler } from './error-handler'

export function apiErrorHandler(
  error: AxiosError,
  status: TypeOptions = 'error'
) {
  const devMode = JSON.parse(
    import.meta.env.VITE_APP_DEVELOPMENT as string
  ) as boolean

  if (devMode) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  if (error.response?.status === 401) {
    errorHandler('Unauthorized', 'error')

    return
  }

  let message: string | string[] = ''

  if (error.response) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = error.response?.data as Record<string, any>

    message = data?.error || data?.message || error.message || data?.errors
  }

  errorHandler(message, status)

  if (typeof message === 'object' && 'sessionId' in message) {
    return message
  }
}
