import { styled } from '@mui/material'

export const PhoneHighLayoutPlaceForPhoto = styled('div')(() => {
  return {
    width: 'calc(100% - 88px)',
    aspectRatio: '0.75',
    borderRadius: '100%',
    border: '2px solid #009EE0',
    boxShadow: '0 0  0 1000px #00000070',
    margin: '0 auto',
  }
})
