/**
 *  Converts a string to a HEX color.
 * @param string The string to convert to color.
 * @returns The HEX color.
 */
export function stringToColor(string: string): string {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff

    color += `00${value.toString(16)}`.substr(-2)
  }

  return color
}

export function convertStringToRGBColor(
  str: string,
  threshold: number = 120
): string {
  // Проверяем, чтобы пороговое значение было в допустимом диапазоне
  threshold = Math.min(Math.max(0, threshold), 120)

  // Получаем хеш строки
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  // Получаем три компоненты цвета из хеша
  let red = (hash & 0xff) % threshold
  let green = ((hash >> 8) & 0xff) % threshold
  let blue = ((hash >> 16) & 0xff) % threshold

  // Если какая-либо из компонентов цвета превышает порог, уменьшаем его до порога
  if (red > threshold) {
    red = threshold
  }

  if (green > threshold) {
    green = threshold
  }

  if (blue > threshold) {
    blue = threshold
  }

  return `rgb(${red}, ${green}, ${blue})`
}
