export const steelBlue = {
  50: '#EEF1F6',
  100: '#DEE3ED',
  200: '#BCC7DC',
  300: '#9BACCA',
  400: '#7A90B8',
  500: '#6982AF',
  600: '#475D85',
  700: '#354664',
  800: '#232E43',
  900: '#121721',
  950: '#090C11',
}
