import { SxProps } from '@mui/material'
import { ForwardedRef, forwardRef } from 'react'
import Webcam from 'react-webcam'
import { Wrapper } from './styled'

interface IProps {
  sx?: SxProps
  start: boolean
}

function DesktopWebcamWithRef(
  { sx, start }: IProps,
  webcamRef: ForwardedRef<Webcam | null>
) {
  return (
    <Wrapper
      sx={{
        ...sx,
      }}
    >
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{ facingMode: 'environment', aspectRatio: 4 / 3 }}
        imageSmoothing={true}
        screenshotQuality={1}
        height={start ? 1924 : undefined}
        width={start ? 2560 : undefined}
        minScreenshotHeight={1924}
        minScreenshotWidth={2560}
      />
    </Wrapper>
  )
}

export const DrDesktopView = forwardRef(DesktopWebcamWithRef)
