import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface IMobileMenu {
  showMobileMenu: boolean
  setShowMobileMenu: (show: boolean) => void
}

export const useMobileMenu = create<IMobileMenu>()(
  devtools(
    (set) => ({
      showMobileMenu: false,
      setShowMobileMenu: (show) => set(() => ({ showMobileMenu: show })),
    }),
    { name: 'cryptoAppBarStore' }
  )
)
