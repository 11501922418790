export const red = {
  50: '#F9EBEB',
  A50: '#FFE8E8',
  100: '#F4D7D7',
  200: '#E8B0B0',
  300: '#DD8888',
  400: '#D16161',
  500: '#C63939',
  600: '#9E2E2E',
  700: '#772222',
  800: '#4F1717',
  900: '#280B0B',
  950: '#140606',
}
