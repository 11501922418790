import { toast, TypeOptions } from 'react-toastify'

import keycloak from '@shared/keycloak'

export type TError = string | string[]

export function errorHandler(error: TError, status: TypeOptions = 'error') {
  if (Array.isArray(error) && error.length) {
    return toast(error.join('/n'), { type: status })
  }

  if (typeof error === 'object' && 'sessionId' in error) {
    return toast('Registration session was interrupted', { type: status })
  }

  if (typeof error === 'object') {
    let errorMessage

    for (const key in error) {
      errorMessage = `${key}: ${error[key]}\n`
    }

    return toast(errorMessage || 'Oops. Something wrong...', { type: status })
  }

  if (error === 'Unauthorized') {
    keycloak.login()
  }

  toast.error(error || 'Oops. Something wrong...')
}
