import { Avatar, ButtonBase } from '@mui/material'
import { useAuthStore } from '@shared/auth'
import { convertStringToRGBColor } from '@shared/utils'

interface IProps {
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  open?: boolean
}

export function ProfileButton({ handleClick, open }: IProps) {
  const getUserFullName = useAuthStore((state) => state.getUserFullName)
  const getUserInitials = useAuthStore((state) => state.getUserInitials)

  const userFullName = getUserFullName() || 'unverified user'
  const userInitials = getUserInitials() || 'UU'

  return (
    <ButtonBase
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      sx={{ border: 'none' }}
    >
      <Avatar
        variant="rounded"
        sx={{
          backgroundColor: convertStringToRGBColor(userFullName),
        }}
        alt={userFullName}
      >
        {userInitials}
      </Avatar>
    </ButtonBase>
  )
}
