export const green = {
  50: '#E7FDF2',
  100: '#CFFCE4',
  200: '#A0F8C9',
  300: '#70F5AE',
  400: '#40F293',
  500: '#098140',
  600: '#0DBF60',
  700: '#0A8F48',
  800: '#075F30',
  900: '#033018',
  950: '#02180C',
}
