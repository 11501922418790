import { styled } from '@mui/material'

export const PhoneHighLayoutTextTitle = styled('p')(() => ({
  margin: 0,
  fontWeight: 500,
  fontSize: 28,
  lineHeight: 1.2,
  textAlign: 'center',
  color: '#FFFFFF',
}))
