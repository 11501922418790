import { Stack, Typography } from '@mui/material'

import { StyledMenuItem } from '../styled'

interface IProps {
  title: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  action: () => void
}

export function ProfileMenuItem({ action, Icon, title }: IProps) {
  return (
    <StyledMenuItem onClick={action} className="logout_button">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1.25}
      >
        <Icon width={20} height={20} />
        <Typography color="primary.main">{title}</Typography>
      </Stack>
    </StyledMenuItem>
  )
}
