import { ConfirmProvider } from 'material-ui-confirm'
import { PropsWithChildren } from 'react'

export function ConfirmationProvider(props: PropsWithChildren<object>) {
  return (
    <ConfirmProvider
      defaultOptions={{
        dialogProps: {
          sx: {
            '& .MuiPaper-root': {
              borderRadius: '8px',
            },
          },
        },
      }}
      children={props.children}
    />
  )
}
