import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

export const IdentityVerificationRoute: RouteObject = {
  lazy: async () => {
    const { IdentityVerification } = await import('./index')

    return { Component: IdentityVerification }
  },
  children: [
    {
      path: routePaths.identityVerification,
      lazy: async () => {
        const { StartVerification } = await import(
          '@widgets/identity-verification'
        )

        return {
          Component: StartVerification,
        }
      },
    },
    {
      path: routePaths.identityVerificationDocumentType,
      lazy: async () => {
        const { SelectDocumentType } = await import(
          '@widgets/identity-verification'
        )

        return {
          Component: SelectDocumentType,
        }
      },
    },
    {
      path: routePaths.identityVerificationScanDocument,
      lazy: async () => {
        const { ScanDocuments } = await import('@widgets/identity-verification')

        return {
          Component: ScanDocuments,
        }
      },
    },
    {
      path: routePaths.identityVerificationSelfie,
      lazy: async () => {
        const { SelfieVerification } = await import(
          '@widgets/identity-verification'
        )

        return {
          Component: SelfieVerification,
        }
      },
    },
    {
      path: routePaths.identityVerificationRecognition,
      lazy: async () => {
        const { Recognition } = await import('@widgets/identity-verification')

        return {
          Component: Recognition,
        }
      },
    },
    {
      path: routePaths.identityVerificationSuccess,
      lazy: async () => {
        const { VerificationDone } = await import(
          '@widgets/identity-verification'
        )

        return {
          Component: VerificationDone,
        }
      },
    },
    {
      path: routePaths.identityVerificationFailed,
      lazy: async () => {
        const { VerificationWrong } = await import(
          '@widgets/identity-verification'
        )

        return {
          Component: VerificationWrong,
        }
      },
    },
  ],
}
