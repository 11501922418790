import { Stack } from '@mui/material'

import { Profile } from '@features/app-bar'

export function DesktopMenu() {
  return (
    <Stack direction="row" height="100%" alignItems="center" spacing={5}>
      <Profile />
    </Stack>
  )
}
