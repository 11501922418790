import { Typography, styled } from '@mui/material'

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 68,
  fontWeight: 700,
  lineHeight: '110%',
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
    fontSize: 80,
  },
}))
