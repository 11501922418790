import { useConfirm } from 'material-ui-confirm'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@shared/auth'

export const useLogoutHandler = () => {
  const confirm = useConfirm()
  const { logout } = useAuth()
  const { t } = useTranslation('features')

  const logoutHandler = () => {
    confirm({
      title: t('profile.logout'),
      description: t('profile.are-you-sure'),
    }).then(() => {
      logout()
    })
  }

  return logoutHandler
}
