import { styled } from '@mui/material'

export const PhoneLayoutWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000000',
  zIndex: theme.zIndex.appBar + 102,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
