import { CssBaseline } from '@mui/material'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import '@shared/i18n'

import { CookieConfirmation } from '@widgets/cookies-confirmations'

import {
  InactiveNotification,
  useInactiveNotificationStore,
} from '@entities/inactive-notification'

import { useMobileWebviewStore } from '@shared/hooks'

import { ComposeProvider } from './providers'
import { RouterViewer } from './router'

export default function App() {
  const isMobile = useMobileWebviewStore((state) => state.isMobile)

  const setShowInactiveNotification = useInactiveNotificationStore(
    (state) => state.setShowNotification
  )

  const resetInactiveNotificationTimer = useInactiveNotificationStore(
    (state) => state.resetTimer
  )

  const stopInactiveNotificationTimer = useInactiveNotificationStore(
    (state) => state.stopTimer
  )

  useEffect(() => {
    const events = [
      'mousemove',
      'keydown',
      'mousedown',
      'touchstart',
      'resize',
      'scroll',
    ]

    setShowInactiveNotification(false)

    events.forEach((event) => {
      window.addEventListener(event, resetInactiveNotificationTimer)
    })

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetInactiveNotificationTimer)
      })

      stopInactiveNotificationTimer()
    }
  }, [])

  return (
    <ComposeProvider>
      <CssBaseline />
      {!isMobile && <CookieConfirmation />}

      <ToastContainer
        newestOnTop
        position="bottom-right"
        limit={isMobile ? 2 : undefined}
      />

      <RouterViewer />
      {process.env.NODE_ENV !== 'development' && <InactiveNotification />}
    </ComposeProvider>
  )
}
