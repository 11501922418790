import { ARBIEX_URL } from '@shared/constants'
import { api } from '@shared/http-instance'
import { IUser } from '../types'

export async function getUserRequest(): Promise<null | IUser> {
  try {
    const url = ARBIEX_URL + `/user`

    const response = await api.get<IUser>(url)

    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return null
  }
}
