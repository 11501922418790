import { configs } from '@shared/constants'

const { pathToLanding } = configs()

export const routePaths = {
  callback: '/callback',

  identityVerification: '/',
  identityVerificationDocumentType: '/document-type',
  identityVerificationScanDocument: '/scan-document',
  identityVerificationSelfie: '/selfie',
  identityVerificationRecognition: '/recognition',
  identityVerificationSuccess: '/success',
  identityVerificationFailed: '/failed',
}

export const externalLinks = {
  landingContacts: `${pathToLanding}/contact-us`,
  privacyPolicyArbitas: `${pathToLanding}/privacy-policy`,
  privacyPolicyArbiex: 'https://arbiex.eu/privacy-policy',
}
