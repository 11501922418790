import { styled } from '@mui/material'

export const SpaceBox = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  position: 'absolute',
  top: 11,
  left: 11,
  zIndex: 2,
  width: 178,
  height: 178,
  background: theme.palette.primary.dark,
  overflow: 'hidden',
}))
