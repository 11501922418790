import { Box, IconButton } from '@mui/material'
import { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SwipeableHandlers } from 'react-swipeable'
import Webcam from 'react-webcam'
import { TakePhotoIcon } from './assets/take-photo-icon'
import {
  PhoneHighLayout,
  PhoneHighLayoutFooter,
  PhoneHighLayoutPlaceForPhoto,
  PhoneHighLayoutTextSubtitle,
  PhoneHighLayoutTextTitle,
  PhoneHighLayoutTitle,
  PhoneLayoutWrapper,
} from './styled'

interface IProps {
  handlers: SwipeableHandlers
  start: boolean
  title: string
  subtitle: string
  onTakePhoto?: () => void
}

function MobileWebcamWithRef(
  { handlers, start, title, subtitle, onTakePhoto }: IProps,
  webcamRef: ForwardedRef<Webcam | null>
) {
  const { t } = useTranslation('pages')

  return (
    <PhoneLayoutWrapper>
      <PhoneHighLayout {...handlers}>
        <PhoneHighLayoutTitle>
          {t('landing.identityVerification.To exit full screen mode')}
        </PhoneHighLayoutTitle>

        {/*Center content -> webcam block with border*/}
        <Box position={'relative'} zIndex={-1}>
          <PhoneHighLayoutPlaceForPhoto>
            <Box className={'border'} />
          </PhoneHighLayoutPlaceForPhoto>

          <Webcam
            style={{
              position: 'absolute',
              zIndex: -1,
              top: '50%',
              left: '50%',
              transform: start
                ? 'translate(-50%, -50%) scale(0.25)'
                : 'translate(-50%, -50%)',
            }}
            videoConstraints={{
              facingMode: 'environment',
              aspectRatio: 4 / 3,
            }}
            screenshotFormat="image/jpeg"
            ref={webcamRef}
            imageSmoothing={true}
            screenshotQuality={1}
            height={start ? 1924 : 480}
            width={start ? 2560 : 640}
            minScreenshotHeight={1924}
            minScreenshotWidth={2560}
          />
        </Box>

        {/*Footer content to take pfoto*/}
        <PhoneHighLayoutFooter>
          <PhoneHighLayoutTextTitle>{title}</PhoneHighLayoutTextTitle>
          <PhoneHighLayoutTextSubtitle>{subtitle}</PhoneHighLayoutTextSubtitle>

          <IconButton
            disabled={start}
            onClick={onTakePhoto}
            sx={{ mg: 5.75, mx: 'auto', mt: 2 }}
          >
            <TakePhotoIcon sx={{ fontSize: 70 }} />
          </IconButton>
        </PhoneHighLayoutFooter>
      </PhoneHighLayout>
    </PhoneLayoutWrapper>
  )
}

export const DrMobileView = forwardRef(MobileWebcamWithRef)
