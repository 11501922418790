import { Box, styled } from '@mui/material'

import { DetectionBrowser } from '@shared/utils'

import TextBgPNG from '../../assets/oops-bg.png'
import TextBgWebP from '../../assets/oops-bg.webp'

export const StyledBox = styled(Box)(() => ({
  width: 'max-content',
  backgroundClip: 'text',
  backgroundImage: `url(${
    DetectionBrowser() === 'Apple Safari' ? TextBgPNG : TextBgWebP
  })`,
  backgroundSize: 'cover',
  color: 'transparent',
}))
