import { styled } from '@mui/material'

export const PaperRounded = styled('div')<{
  transparent?: boolean
}>(({ theme, transparent = true }) => ({
  borderRadius: 16,
  backgroundColor: transparent ? 'transparent' : theme.palette.common.white,
  boxShadow: transparent ? 'unset' : '0px 2px 12px rgba(0, 0, 0, 0.08)',
  padding: transparent ? 0 : theme.spacing(1.5),
  transition: theme.transitions.create(
    ['border-color', 'background-color', 'border-radius'],
    {
      duration: theme.transitions.duration.standard,
    }
  ),
  [theme.breakpoints.up('md')]: {
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.08)',
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(3)} ${theme.spacing(5.5)}`,
  },
}))
