import { MenuItem, styled } from '@mui/material'

interface IProps {
  isBlue: boolean
}

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isBlue',
})<IProps>(({ theme, isBlue }) => ({
  width: 'min-content',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: theme.spacing(1),
  color: isBlue ? theme.palette.primary.light : undefined,
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.standard,
  }),
}))
