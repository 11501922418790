import { ErrorPage } from '@pages/error'

import { StyledContainer } from './styled'

export function ErrorLayout() {
  return (
    <StyledContainer maxWidth="xl">
      <ErrorPage />
    </StyledContainer>
  )
}
