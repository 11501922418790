import { Stack, styled } from '@mui/material'

import BgMobile from '../../assets/error-page-bg-mobile.svg'
import Bg from '../../assets/error-page-bg.svg'

export const StyledStack = styled(Stack)(({ theme }) => ({
  maxWidth: '100%',
  minHeight: 280,
  marginTop: theme.spacing(17),
  paddingTop: theme.spacing(4.5),
  flexGrow: 1,
  position: 'relative',
  backgroundImage: `url(${BgMobile})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  [theme.breakpoints.up('md')]: {
    maxWidth: 'initial',
    minHeight: 364,
    paddingTop: theme.spacing(0),
    marginTop: theme.spacing(0),
    backgroundPosition: 'center center',
    backgroundImage: `url(${Bg})`,
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: 0,
  },
}))
