export const yellow = {
  50: '#FFF9E6',
  100: '#FFF4CC',
  200: '#FFE999',
  300: '#FEDE67',
  400: '#FED234',
  500: '#F6C001',
  600: '#CB9F01',
  700: '#987801',
  800: '#665000',
  900: '#332800',
  950: '#191400',
}
