import { styled } from '@mui/material'

export const PhoneHighLayoutTextSubtitle = styled('p')(() => ({
  margin: 0,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  textAlign: 'center',
  color: '#FFFFFF',
}))
