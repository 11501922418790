import { styled, Typography } from '@mui/material'
import { grey } from '@shared/constants'

export const StyledContactText = styled(Typography)(({ theme }) => ({
  width: 210,
  textAlign: 'center',
  color: grey[500],
  [theme.breakpoints.up('md')]: {
    width: 'max-content',
    display: 'inline-block',
  },
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
