export default function SelfieVectorSVG() {
  return (
    <svg
      width="205"
      height="272"
      viewBox="0 0 205 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102.5 271C130.638 271 156.035 255.791 174.364 231.352C192.693 206.914 204 173.199 204 136C204 98.8015 192.693 65.086 174.364 40.6477C156.035 16.209 130.638 1 102.5 1C74.3624 1 48.9648 16.209 30.6358 40.6477C12.307 65.086 1 98.8015 1 136C1 173.199 12.307 206.914 30.6358 231.352C48.9648 255.791 74.3624 271 102.5 271Z"
        fill="#E5F7FF"
        stroke="#009EE0"
        strokeWidth="2"
      />

      <mask
        id="mask0_1642_15238"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="201"
        height="268"
      >
        <ellipse cx="102.5" cy="136" rx="100.5" ry="134" fill="#D9D9D9" />
      </mask>

      <g mask="url(#mask0_1642_15238)">
        <ellipse cx="102.5" cy="136" rx="100.5" ry="134" fill="#E5F7FF" />

        <path
          opacity="0.4"
          d="M102.632 70.675C75.4224 70.675 53.3013 92.7961 53.3013 120.006C53.3013 146.697 74.1762 168.299 101.386 169.233C102.217 169.13 103.048 169.13 103.671 169.233H104.398C117.165 168.807 129.267 163.433 138.144 154.246C147.02 145.059 151.976 132.781 151.964 120.006C151.964 92.7961 129.842 70.675 102.632 70.675Z"
          fill="#009EE0"
        />

        <path
          d="M155.391 196.849C126.416 177.532 79.1618 177.532 49.9785 196.849C36.7889 205.676 29.519 217.62 29.519 230.394C29.519 243.168 36.7889 255.008 49.8746 263.731C64.4144 273.494 83.5237 278.375 102.633 278.375C121.742 278.375 140.852 273.494 155.391 263.731C168.477 254.904 175.747 243.064 175.747 230.186C175.643 217.412 168.477 205.572 155.391 196.849Z"
          fill="#0155B7"
        />
      </g>
    </svg>
  )
}
