import { styled, Box } from '@mui/material'

export const UnderWrapper = styled(Box)(() => ({
  width: 240,
  height: 320,
  borderRadius: '100%',
  border: '4px solid #009EE0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: 1000,
  '& video': {
    zIndex: 999,
    width: 480,
    display: 'block',
  },
}))
