import { styled } from '@mui/material'

export const PhoneHighLayoutPlaceForPhoto = styled('div')(() => {
  return {
    width: 'calc(100% - 56px)',
    aspectRatio: '1.47',
    borderRadius: 8,
    border: '2px solid #009EE0',
    boxShadow: '0 0  0 1000px #000000',
    margin: '0 auto',
  }
})
