import { styled } from '@mui/material'

export const PhoneHighLayout = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  paddingTop: '20px',
  paddingBottom: '20px',
  zIndex: theme.zIndex.appBar + 104,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))
