import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { firstElementUp } from '@shared/utils'
import { IUser, IUserDetailInfo } from '../types'

interface IAuthStoreState {
  // State
  inProcess: boolean
  user: IUser | null
  userDetail: IUserDetailInfo | null

  isUserDetailsLoading: boolean
  setIsUserDetailsLoading: (payload: boolean) => void

  //Mutations
  setInProcess: (payload: boolean) => void
  setUser: (user: IUser | null) => void
  setUserDetailInfo: (userDetail: IUserDetailInfo | null) => void

  // Getters
  getUserFullName: () => string
  getUserInitials: () => string
  getEmail: () => string
}

export const useAuthStore = create<IAuthStoreState>()(
  devtools(
    (set, get) => ({
      inProcess: true,
      user: null,
      userDetail: null,

      isUserDetailsLoading: true,
      setIsUserDetailsLoading: (isUserDetailsLoading) =>
        set({ isUserDetailsLoading }),

      setUser: (user) => set(() => ({ user })),
      setInProcess: (payload) => set(() => ({ inProcess: payload })),
      setUserDetailInfo: (userDetail) => set(() => ({ userDetail })),

      getUserFullName: () => {
        const firstName = get().userDetail?.firstName
        const lastName = get().userDetail?.lastName

        if (!firstName || !lastName) {
          return ''
        }

        return `${firstElementUp(firstName.toLowerCase())} ${firstElementUp(
          lastName.toLowerCase()
        )}`
      },
      getUserInitials: () => {
        const firstName = get().userDetail?.firstName
        const lastName = get().userDetail?.lastName

        if (!firstName || !lastName) {
          return ''
        }

        return `${firstName[0]}${lastName[0]}`
      },
      getEmail: () => get().user?.emailAddress || '',
    }),
    { name: 'auth-store' }
  )
)
