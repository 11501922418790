import { styled } from '@mui/material'

export const PhoneHighLayoutTextTitle = styled('p')(() => ({
  fontWeight: 500,
  fontSize: 28,
  lineHeight: 1.2,
  textAlign: 'center',
  color: '#FFFFFF',
  margin: 0,
  marginBottom: 12,
  letterSpacing: '-0.03em',
}))
