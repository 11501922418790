import { ReactComponent as LogoutIcon } from '../../assets/logout.icon.svg'
import { useLogoutHandler } from '../logout-handler'
import { StyledMenu } from '../styled'
import { ProfileMenuItem } from './profile-menu-item'

interface IMenuItem {
  title: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  action: () => void
}

interface IProps {
  anchorEl: HTMLElement | null
  open: boolean
  handleClose: () => void
}

export function ProfileMenu({ anchorEl, handleClose, open }: IProps) {
  const logoutHandler = useLogoutHandler()

  const menu: IMenuItem[] = [
    {
      title: 'Logout',
      icon: LogoutIcon,
      action: () => logoutHandler(),
    },
  ]

  return (
    <StyledMenu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {menu.map((item: IMenuItem, index: number) => (
        <ProfileMenuItem
          key={index}
          title={item.title}
          Icon={item.icon}
          action={() => {
            handleClose()

            item.action()
          }}
        />
      ))}
    </StyledMenu>
  )
}
