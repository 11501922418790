import { SxProps } from '@mui/material'
import imageCompression from 'browser-image-compression'
import {
  ChangeEvent,
  forwardRef,
  Fragment,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
// import { useSwipeable } from 'react-swipeable'
import Webcam from 'react-webcam'

import { useIsMobileStore } from '@shared/hooks'
import { ITakePhotoResponse } from '@shared/ui'
import SelfieVectorSVG from '@shared/ui/face-reader/components/assets/selfie-vector'
import { base64ToFile, isBase64String } from '@shared/utils'

import { TBase64String } from '../../types'
import {
  FrDesktopView,
  // FrMobileView
} from './components'

export interface IFaceReaderTakePhotoResponse {
  file: File | null
  base64: TBase64String | null
}

export interface IFaceReaderRefType {
  takePhoto: () => Promise<IFaceReaderTakePhotoResponse>
  handleStartProcess: () => void
  setShowMobileLayout: (payload: boolean) => void
  clickOnInput: () => void
}

interface IProps {
  sx?: SxProps
  openPhoneLayout?: boolean
  fileName?: string
  title: string
  subtitle: string | JSX.Element
  onTakePhoto?: (document: IFaceReaderTakePhotoResponse) => void
  onHideMobileLayout?: () => void
  setPhotoResult: (value: IFaceReaderTakePhotoResponse | null) => void
}

const videoConstraints = {
  width: { min: 720 },
  height: { min: 720 },
  aspectRatio: 1,
}

function FaceReaderRef(
  {
    sx,
    openPhoneLayout,
    fileName,
    // subtitle, title,
    onTakePhoto,
    setPhotoResult,
  }: IProps,
  ref: Ref<IFaceReaderRefType>
) {
  const [startProcess, setStartProcess] = useState<boolean>(false)
  const [showMobileLayout, setShowMobileLayout] = useState<boolean>(true)
  const webcamRef = useRef<Webcam | null>(null)

  // const handlers = useSwipeable({
  //   onSwipedUp: () => {
  //     setShowMobileLayout(false)
  //   },
  // })

  useEffect(() => {
    document.body.style.overflowY =
      openPhoneLayout && showMobileLayout && startProcess ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [openPhoneLayout, showMobileLayout, startProcess])

  const takePhoto = async (): Promise<IFaceReaderTakePhotoResponse> => {
    const response: IFaceReaderTakePhotoResponse = {
      file: null,
      base64: null,
    }

    if (!webcamRef.current) {
      return response
    }

    const screenshotBase64 =
      webcamRef.current.getScreenshot() as TBase64String | null

    // Checking whether the screenshotBase64 belongs to the TBase64String type
    if (screenshotBase64 && !isBase64String(screenshotBase64)) {
      return response
    }

    response.base64 = screenshotBase64

    if (!response.base64) {
      return response
    }

    response.file = await base64ToFile(
      response.base64,
      `${fileName || document}.png`
    )

    if (onTakePhoto) {
      onTakePhoto(response)
    }

    return response
  }

  const takePhotoFromInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const file = target.files?.[0]

    if (!file || !file.type.includes('image')) {
      return
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    try {
      const compressedFile = await imageCompression(file, options)

      const base64 = await new Promise<TBase64String>((resolve) => {
        const reader = new FileReader()

        reader.onload = () => {
          resolve(reader.result as TBase64String)
        }

        reader.readAsDataURL(compressedFile)
      })

      if (!base64) {
        return
      }

      const response: ITakePhotoResponse = {
        file: compressedFile,
        base64,
      }

      target.value = ''

      setPhotoResult(response)
    } catch (e) {
      return
    }
  }

  function handleStartProcess() {
    setStartProcess(true)
  }

  const isMobile = useIsMobileStore((state) => state.isMobile)
  const inputFileRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    takePhoto: takePhoto,
    handleStartProcess,
    setShowMobileLayout,
    clickOnInput: () => {
      inputFileRef.current?.click()
    },
  }))

  return (
    <Fragment>
      {/*{openPhoneLayout && showMobileLayout && startProcess ? (*/}
      {/*  <FrMobileView*/}
      {/*    handlers={handlers}*/}
      {/*    videoConstraints={videoConstraints}*/}
      {/*    takePhoto={takePhoto}*/}
      {/*    title={title}*/}
      {/*    subtitle={subtitle}*/}
      {/*    ref={webcamRef}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <FrDesktopView*/}
      {/*    videoConstraints={videoConstraints}*/}
      {/*    startProcess={startProcess}*/}
      {/*    sx={sx}*/}
      {/*    ref={webcamRef}*/}
      {/*  />*/}
      {/*)}*/}
      {isMobile && (
        <>
          <SelfieVectorSVG />

          <input
            ref={inputFileRef}
            type="file"
            id="picture"
            name="picture"
            accept="image/*"
            capture={'user'}
            style={{ display: 'none' }}
            onChange={(event) => takePhotoFromInput(event)}
          />
        </>
      )}

      {!isMobile && (
        <FrDesktopView
          videoConstraints={videoConstraints}
          startProcess={startProcess}
          sx={sx}
          ref={webcamRef}
        />
      )}
    </Fragment>
  )
}

export const FaceReader = forwardRef(FaceReaderRef)
