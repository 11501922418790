import { Menu, styled } from '@mui/material'

export const StyledMenuLocal = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(0),
  borderRadius: 3,
  '& .MuiPaper-root': {
    width: 66,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  '& .MuiList-root': {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    gap: 2,
  },
}))
