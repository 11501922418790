import { keyframes } from '@emotion/react'
import { styled } from '@mui/material'

import Stars from '../assets/stars.svg'

const StarsAnimation = keyframes`
    100% {
      transform: translateX(-50%);
    }
    `

export const StarsBox = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: 1000000,
  backgroundImage: `url(${Stars})`,
  backgroundRepeat: 'repeat-x',
  backgroundSize: 'contain',
  animation: `${StarsAnimation} 600s infinite linear`,
}))
