import { SxProps } from '@mui/material'
import imageCompression from 'browser-image-compression'
import {
  ChangeEvent,
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
// import { useSwipeable } from 'react-swipeable'
import Webcam from 'react-webcam'

import { useIsMobileStore } from '@shared/hooks'
import { TBase64String } from '@shared/types'
import {
  // anotherDetectMobileDevice,
  base64ToFile,
  // detectMobileDeviceByUserAgent,
  isBase64String,
} from '@shared/utils'

import {
  DrDesktopView,
  // DrMobileView
} from './components'

export interface ITakePhotoResponse {
  file: File | null
  base64: TBase64String | null
}

export interface IDocumentsReaderRef {
  takePhoto: () => Promise<ITakePhotoResponse>
  setShowMobileLayout: (payload: boolean) => void
  clickOnInput: () => void
}

interface IProps {
  sx?: SxProps
  openPhoneLayout?: boolean
  fileName?: string
  title: string
  subtitle: string
  onTakePhoto?: () => void
  onHideMobileLayout?: () => void
  setPhotoResult: (value: ITakePhotoResponse | null) => void
}

function DocumentsReaderRef(
  {
    sx,
    openPhoneLayout,
    fileName,
    setPhotoResult,
    // subtitle,
    // title,
    // onTakePhoto,
    // onHideMobileLayout,
  }: IProps,
  ref: Ref<IDocumentsReaderRef>
) {
  const [start, setStart] = useState<boolean>(false)
  const [showMobileLayout, setShowMobileLayout] = useState<boolean>(true)
  const webcamRef = useRef<Webcam | null>(null)

  // const handlers = useSwipeable({
  //   onSwipedUp: () => {
  //     setShowMobileLayout(false)
  //
  //     if (onHideMobileLayout) {
  //       onHideMobileLayout()
  //     }
  //   },
  // })

  useEffect(() => {
    document.body.style.overflowY =
      openPhoneLayout && showMobileLayout ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [openPhoneLayout, showMobileLayout])

  const takePhoto = async (): Promise<ITakePhotoResponse> => {
    const response: ITakePhotoResponse = {
      file: null,
      base64: null,
    }

    if (start) {
      return response
    }

    setStart(true)

    if (!webcamRef.current) {
      setStart(false)

      return response
    }

    const screenshotBase64 = webcamRef.current.getScreenshot()

    if (!isBase64String(screenshotBase64)) {
      return response
    }

    response.base64 = screenshotBase64

    if (!response.base64) {
      setStart(false)

      return response
    }

    response.file = await base64ToFile(
      response.base64,
      `${fileName || document}.png`
    )

    setStart(false)

    return response
  }

  const takePhotoFromInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const file = target.files?.[0]

    if (!file || !file.type.includes('image')) {
      return
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    try {
      const compressedFile = await imageCompression(file, options)

      const base64 = await new Promise<TBase64String>((resolve) => {
        const reader = new FileReader()

        reader.onload = () => {
          resolve(reader.result as TBase64String)
        }

        reader.readAsDataURL(compressedFile)
      })

      if (!base64) {
        return
      }

      const response: ITakePhotoResponse = {
        file: compressedFile,
        base64,
      }

      target.value = ''

      setPhotoResult(response)
    } catch (error) {
      return
    }
  }

  const isMobile = useIsMobileStore((state) => state.isMobile)
  const inputFileRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    takePhoto,
    setShowMobileLayout,
    clickOnInput: () => {
      inputFileRef.current?.click()
    },
  }))

  return (
    <>
      {/*{openPhoneLayout && showMobileLayout ? (*/}
      {/*  <DrMobileView*/}
      {/*    handlers={handlers}*/}
      {/*    start={start}*/}
      {/*    title={title}*/}
      {/*    subtitle={subtitle}*/}
      {/*    onTakePhoto={onTakePhoto}*/}
      {/*    ref={webcamRef}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <DrDesktopView start={start} sx={sx} ref={webcamRef} />*/}
      {/*)}*/}

      {isMobile && (
        <input
          ref={inputFileRef}
          type="file"
          id="picture"
          name="picture"
          accept="image/*"
          capture={'environment'}
          style={{ display: 'none' }}
          onChange={(event) => takePhotoFromInput(event)}
        />
      )}

      {!isMobile && <DrDesktopView start={start} sx={sx} ref={webcamRef} />}
    </>
  )
}

export const DocumentsReader = forwardRef(DocumentsReaderRef)
