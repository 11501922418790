import { MenuItem, styled } from '@mui/material'
import { blue } from '@shared/constants'

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.logout_button': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: theme.transitions.create(
      ['fill', 'color', 'background-color'],
      {
        duration: theme.transitions.duration.standard,
      }
    ),
    width: '100%',

    padding: '10px 26px',
    background: blue[30],
    '&.logOut_button:hover': {
      backgroundColor: theme.palette.primary.light,
      transition: theme.transitions.create('background-color'),
    },
  },
}))
