import { styled, Stack } from '@mui/material'

export const StyledContactContainer = styled(Stack)(({ theme }) => ({
  padding: '34px 0 0',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    top: 24,
    width: '94vw',
    alignItems: 'flex-end',
    paddingRight: '64px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '97vw',
  },
  [theme.breakpoints.up('xl')]: {
    width: '89vw',
  },
}))
