import { PaletteOptions } from '@mui/material'

import { blue, green, grey, red, yellow } from '@shared/constants'

export const paletteOptions = {
  common: {
    white: '#FFFFFF',
    black: blue[950],
  },
  primary: {
    dark: '#0065AE',
    main: '#009EE0',
    light: '#CDE4EC',
    contrastText: '#FFFFFF',
  },
  secondary: {
    dark: '#e200ff',
    main: '#e200ff',
    light: '#e200ff',
    contrastText: '#e200ff',
  },
  text: {
    primary: grey.A800,
    secondary: grey[500],
    disabled: grey[200],
  },
  success: {
    dark: green[800],
    main: green[500],
    light: green[100],
    contrastText: '#FFFFFF',
  },
  error: {
    dark: red[700],
    main: red[500],
    light: red.A50,
    contrastText: '#FFFFFF',
  },
  warning: {
    dark: yellow[700],
    main: yellow[500],
    light: yellow[100],
    contrastText: grey.A800,
  },
  info: {
    dark: blue[800],
    main: blue.A700,
    light: blue[100],
    contrastText: '#FFFFFF',
  },
} satisfies PaletteOptions
