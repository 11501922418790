export function displayTimerCount(count: number) {
  const res = Math.floor(count / 1000)
  let seconds = res % 60
  const minutes = (res - seconds) / 60

  if (seconds < 0) {
    seconds = 0
  }

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}
