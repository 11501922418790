import { Container, Stack } from '@mui/material'

import {
  // ProjectsSwitcher,
  useProjectsSwitcherStore,
} from '@features/projects-switcher'

import { DesktopMenu, Logo } from './components'

import { StyledAppBar, StyledToolbar } from './styled'

export function AppBar() {
  const setAnchorEl = useProjectsSwitcherStore((state) => state.setAnchorEl)
  // const placement = useProjectsSwitcherStore((state) => state.placement)

  return (
    <>
      <StyledAppBar position="sticky" onClick={() => setAnchorEl(null)}>
        <Container
          maxWidth="xl"
          sx={{ px: { xs: 0, lg: 3 }, position: 'relative' }}
        >
          <StyledToolbar
            sx={{ position: 'relative', zIndex: 3, py: 1 }}
            disableGutters={true}
          >
            <Stack direction="row" spacing={2.5}>
              {/*{placement === 'left' && <ProjectsSwitcher />}*/}
              <Logo />
            </Stack>

            <DesktopMenu />
          </StyledToolbar>
        </Container>
      </StyledAppBar>
    </>
  )
}
