import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface IInactiveNotificationStore {
  timeoutValue: number //milliseconds
  showNotification: boolean
  timer: ReturnType<typeof setTimeout> | number
  setShowNotification: (status: boolean) => void
  resetTimer: () => void
  stopTimer: () => void
}

export const useInactiveNotificationStore =
  create<IInactiveNotificationStore>()(
    devtools(
      (set, get) => ({
        timeoutValue: 60 * 1000 * 10,
        showNotification: false,
        timer: 0,

        setShowNotification: (status) => {
          set({ showNotification: status })

          if (!status) {
            get().resetTimer()
          }
        },

        stopTimer: () => {
          clearTimeout(get().timer)
        },

        resetTimer: () => {
          if (get().showNotification) {
            return
          }

          get().stopTimer()

          const timer = setTimeout(() => {
            get().setShowNotification(true)
          }, get().timeoutValue)

          set({ timer })
        },
      }),
      { name: 'inactiveNotification' }
    )
  )
