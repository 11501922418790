import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete,
  Box,
  TextField,
  createFilterOptions,
} from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

import { ICountry, countries } from '@shared/constants'

import { IInputProps } from '@shared/types'

interface IProps extends IInputProps<unknown[]> {
  onChange?: (...event: unknown[]) => void
  variant?: 'filled' | 'outlined' | 'standard'
  listMode?: 'countries' | 'countryCodes'
}

export const CountrySelectInput = forwardRef<HTMLInputElement, IProps>(
  function Named(props: IProps, ref) {
    const {
      placeholder,
      error,
      helperText,
      label,
      onChange,
      onBlur,
      sx,
      value,
      variant,
      name,
      disabled,
      fullWidth,
      listMode,
    } = props

    const [autocompleteValue, setAutocompleteValue] = useState<ICountry | null>(
      countries.find((item) => item.abbreviation === value) || null
    )

    const optionsToGetCountryByNameOrAbrev = createFilterOptions({
      matchFrom: 'any',
      stringify: (option: ICountry) => `${option.name} ${option.abbreviation}`,
    })

    useEffect(() => {
      setAutocompleteValue(
        countries.find((item) => item.abbreviation === value) || null
      )
    }, [value])

    return (
      <Box sx={sx}>
        <Autocomplete
          disabled={disabled}
          fullWidth={fullWidth}
          value={autocompleteValue}
          defaultValue={null}
          clearOnBlur
          filterOptions={optionsToGetCountryByNameOrAbrev}
          disablePortal
          options={countries}
          getOptionLabel={(option) =>
            !!listMode && listMode === 'countryCodes'
              ? option.abbreviation
              : option.name
          }
          popupIcon={!disabled && <ExpandMoreIcon sx={{ color: '#009EE0' }} />}
          onChange={(_, option) => {
            if (onChange) {
              onChange(option?.abbreviation)
            }
          }}
          onBlur={onBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              inputRef={ref}
              variant={variant}
              fullWidth={fullWidth}
              placeholder={placeholder}
              error={error}
              name={name}
              helperText={helperText || ''}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <SearchIcon sx={{ width: 0, opacity: 0, mr: 1 }} />
                ),
              }}
            />
          )}
        />
      </Box>
    )
  }
)
