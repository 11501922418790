export const blue = {
  30: '#F4FCFF',
  50: '#E7F8FD',
  100: '#CFF1FC',
  200: '#A0E2F8',
  300: '#70D4F5',
  400: '#40C5F2',
  500: '#096381',
  600: '#0D92BF',
  700: '#0A6E8F',
  A700: '#096481',
  800: '#07495F',
  900: '#032530',
  950: '#021218',
}
