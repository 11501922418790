import { useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useRouteError } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { PageNotFound } from './components'
import {
  StyledStack,
  StyledBox,
  StyledTitleTypography,
  StyledTextTypography,
  StyledButton,
} from './styled'

export function ErrorPage() {
  const { t } = useTranslation('pages')
  const navigate = useNavigate()
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))
  const error = useRouteError()

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  const isStatus404 = useMemo(
    () =>
      !!error &&
      typeof error === 'object' &&
      'status' in error &&
      error.status === 404,
    []
  )

  const handleClick = () => {
    navigate(routePaths.identityVerification)
  }

  return (
    <>
      <StyledStack alignItems="center" justifyContent={{ md: 'center' }}>
        <StyledBox>
          <StyledTitleTypography>Oops!</StyledTitleTypography>
        </StyledBox>

        {isStatus404 ? (
          <PageNotFound />
        ) : (
          <StyledTextTypography textAlign="center" isStatus404={isStatus404}>
            {t('error.Something went wrong')}
          </StyledTextTypography>
        )}

        {mdAndUp && (
          <StyledButton variant="contained" onClick={handleClick}>
            {t('error.button')}
          </StyledButton>
        )}
      </StyledStack>

      {!mdAndUp && (
        <StyledButton
          variant="contained"
          onClick={handleClick}
          fullWidth
          sx={{ maxWidth: 328, mx: 'auto', mb: 10 }}
        >
          {t('error.button')}
        </StyledButton>
      )}
    </>
  )
}
